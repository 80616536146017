import theming from 'styled-theming';
import {
  getResponsiveBreakpoint,
} from './cssHelpers';

// 1.5715 such exact magic number is to be consistent with Ant Design v.4
// which use same line height across its components
const lineHeightBase = 1.5715;
const ratio = lineHeightBase;

const hue = 210;
const hueAccent = 30;

const primaryBase = `${hue}, 75%, 45%`;
const successBase = '100, 77%, 44%';
const warningBase = '40, 96%, 53%';
const errorBase = '357, 91%, 55%';
const darkBase = `${hue}, 0%, 0%`;
const lightBase = `${hue}, 50%, 100%`;
const backgroundBase = `${hue}, 42%, 94%`;

// Background

// export const onBackgroundColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.75)',
//   dark: 'rgba(255, 255, 255, 0.75)',
// });

// Below

// export const onBelowSurfaceColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.6)',
//   dark: 'rgba(255, 255, 255, 0.6)',
// });

// Above

// export const onAboveSurfaceColorTitle = theming('mode', {
//   light: 'rgba(0, 0, 0, 0.85)',
//   dark: 'rgba(255, 255, 255, 1)',
// });

// Major Second Scale

const scaleRatio = 1.125;
const scale = {
  '-3': scaleRatio ** -3,
  '-2': scaleRatio ** -2,
  '-1': scaleRatio ** -1,
  0: scaleRatio ** 0,
  1: scaleRatio ** 1,
  2: scaleRatio ** 2,
  3: scaleRatio ** 3,
  4: scaleRatio ** 4,
};

// Space 5 Tiers
const space = {};

space['0'] = 0;
space['1'] = ratio * 0.25;
space['2'] = ratio * 0.5;
space['3'] = ratio * 1;
space['4'] = ratio * 1.5;
space['5'] = ratio * 1.75;

// 5 Tiers
const measure = {};
measure['3'] = '60ch';

// Up
measure['4'] = '75ch';
measure['5'] = '90ch';

// Down
measure['2'] = '45ch';
measure['1'] = '30ch';
measure['0'] = '0ch';

export default {
  font: {
    family: `"Work Sans", -apple-system, BlinkMacSystemFont, Roboto,
      "Helvetica Neue", Helvetica, Arial, sans-serif`,
    size: {
      xs: `${1 - ratio * 0.25}rem`,
      small: `${1 - ratio * (0.25 / 2)}rem`,
      smaller: `${1 - ratio * (0.25 / 2)}em`,
      base: '1rem',
      larger: `${ratio * 0.75}em`,
      large: `${ratio * 0.75}rem`,
    },
    weight: {
      light: 300,
      bold: 600,
    },
  },
  line: {
    height: {
      base: lineHeightBase,
    },
  },
  color: {
    hue,
    successBase,
    success: `hsl(${successBase})`,
    errorBase,
    error: '#f5222d',
    warningBase,
    warning: '#faad14',
    light: `hsla(${hue}, 100%, 100%, 1)`,
    primaryBase,
    accent: `hsl(${hueAccent}, 98%, 64%)`,
    text: {
      light: `hsl(${hue}, 49%, 30%)`,
      normal: `hsl(${hue}, 42%, 25%)`,
      rich: `hsl(${hue}, 78%, 33%)`,
      primary: `hsl(${hue}, 100%, 45%)`,
      accent: `hsl(${hueAccent}, 100%, 50%)`,
      success: 'hsl(100, 76%, 28%)',
    },
    darkBase,
    dark: `hsla(${darkBase}, 0.15)`,
    backgroundBase,

    // New
    primary: theming('mode', {
      light: '#1976d2',
      dark: '#42a5f5',
    }),
    onPrimary: theming('mode', {
      light: `hsl(${lightBase})`,
      dark: `hsla(${darkBase}, 0.85)`,
    }),
    primaryHover: theming('mode', {
      light: '#1565c0',
      dark: '#2196f3',
    }),
    primaryActive: theming('mode', {
      light: '#0d47a1',
      dark: '#1e88e5',
    }),
    danger: theming('mode', {
      light: '#d32f2f',
      dark: '#ef5350',
    }),
    dangerHover: theming('mode', {
      light: '#c62828',
      dark: '#f44336',
    }),
    dangerActive: theming('mode', {
      light: '#b71c1c',
      dark: '#e53935',
    }),
    background: theming('mode', {
      // light: background.light.value,
      // dark: background.dark.value,
      light: '#F2F2F3',
      dark: '#333335',
    }),
    onBackground: theming('mode', {
      // light: onBackground.light.value,
      // dark: onBackground.dark.value,
      light: `hsla(${darkBase}, 0.7)`,
      dark: `hsla(${lightBase}, 0.7)`,
    }),
    surface: theming('mode', {
      // light: surface.light.value,
      // dark: surface.dark.value,
      light: `hsl(${lightBase})`,
      dark: '#48484A',
    }),
    onSurface: theming('mode', {
      // light: onSurface.light.value,
      // dark: onSurface.dark.value,
      light: `hsla(${darkBase}, 0.9)`,
      dark: `hsla(${lightBase}, 0.9)`,
    }),
    underlay: theming('mode', {
      // light: underlay.light.value,
      // dark: underlay.dark.value,
      light: '#E3E2E3',
      dark: '#262628',
    }),
    onUnderlay: theming('mode', {
      // light: onUnderlay.light.value,
      // dark: onUnderlay.dark.value,
      light: `hsla(${darkBase}, 0.65)`,
      dark: `hsla(${lightBase}, 0.65)`,
    }),
    border: theming('mode', {
      light: `hsla(${darkBase}, 0.1)`,
      dark: `hsla(${lightBase}, 0.1)`,
    }),
    // Semantic
    disabled: theming('mode', {
      light: `hsla(${darkBase}, 0.25)`,
      dark: `hsla(${lightBase}, 0.25)`,
    }),
    focus: theming('mode', {
      light: `hsla(${darkBase}, 0.85)`,
      dark: `hsla(${lightBase}, 0.85)`,
    }),
    hover: theming('mode', {
      light: `hsla(${darkBase}, 0.05)`,
      dark: `hsla(${lightBase}, 0.05)`,
    }),
    active: theming('mode', {
      light: `hsla(${darkBase}, 0.1)`,
      dark: `hsla(${lightBase}, 0.1)`,
    }),
  },
  border: {
    // Such interesting border radius choice is to be consistent with Ant Design 4
    // which components we still rely on
    radius: {
      0: '0em',
      1: '0.0625em',
      2: '0.125em',
      3: '0.1875em',
    },
  },
  screen: {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  breakpoints: {
    mobile: getResponsiveBreakpoint(480),
    tablet: getResponsiveBreakpoint(768),
    laptop: getResponsiveBreakpoint(1200),
    desktop: getResponsiveBreakpoint(1600),
  },
  // Get rid of rhythm
  rhythm: {
    xs: `${lineHeightBase - 1}em`,
    small: `${lineHeightBase - 0.75}em`,
    base: `${lineHeightBase}em`,
    large: `${lineHeightBase + 0.75}em`,
  },
  space: Object.assign(
    {},
    ...Object.keys(space).map(key => ({
      [key]: space[key].toString().concat('rem'),
    })),
  ),
  scale: Object.assign(
    {},
    ...Object.keys(scale).map(key => ({
      [key]: scale[key].toString().concat('rem'),
    })),
  ),
  measure: Object.assign(
    {},
    ...Object.keys(measure).map(key => ({
      [key]: measure[key],
    })),
  ),
  motion: {
    easing: {
      standard: 'cubic-bezier(0.2, 0, 0.38, 0.9)',
      entrance: 'cubic-bezier(0, 0, 0.38, 0.9)',
      exit: 'cubic-bezier(0.2, 0, 1, 0.9)',
    },
    duration: {
      moderate: '150ms',
      fast: '70ms',
      fade: '110ms',
      communication: '240ms',
      slow: '400ms',
    },
  },
};
