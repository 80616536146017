import has from 'lodash/has';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import size from 'lodash/size';
import keys from 'lodash/keys';
import isDate from 'lodash/isDate';
import isNaN from 'lodash/isNaN';

// reference: https://www.hl7.org/fhir/datatypes.html
const formats = {
  decimal: {
    re: /^-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][+-]?[0-9]+)?$/,
    description: 'decimal number',
  },
  instant: {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))$/,
    description: 'timestamp',
  },
  date: {
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1]))?)?$/,
    description: 'date or partial date',
  },
  'date-time': {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?$/,
    description: 'date and time of day',
  },
  time: {
    re: /^([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?$/,
    description: 'time of day',
  },
};

function strLength(str) {
  if (!str) {
    return 0;
  }
  // https://stackoverflow.com/a/54369605/2817257
  return [
    ...str,
  ].length;
}

const defaultUriResolver = (_, relativeURI) => relativeURI;

export function createCheckSchema({
  rootSchema,
  baseURI = '',
  remoteSchemas,
  uriResolver = defaultUriResolver,
} = {}) {
  const localSchemas = {};
  const allSchemas = Object.create(remoteSchemas || {});

  const defineLocalSchema = (uri, schema) => {
    localSchemas[uri] = schema;
    allSchemas[uri] = schema;
  };

  let rootURI = baseURI;
  if (rootSchema) {
    rootURI = uriResolver(baseURI, rootSchema.$id || '');
    defineLocalSchema(rootURI, rootSchema);
  }

  forEach(rootSchema && rootSchema.definitions, (schema) => {
    if (schema.$id) {
      defineLocalSchema(uriResolver(rootURI, schema.$id), schema);
    }
  });

  const getValidateCache = {};
  const getValidate = (relativeURI) => {
    const uri = uriResolver(rootURI, relativeURI);
    if (getValidateCache[uri]) {
      return getValidateCache[uri].checkSchema;
    }
    let validator;
    if (localSchemas[uri]) {
      validator = createCheckSchema({
        uriResolver,
        rootSchema: localSchemas[uri],
        baseURI: uri,
        remoteSchemas: allSchemas,
      });
    } else if (remoteSchemas && remoteSchemas[uri]) {
      validator = createCheckSchema({
        uriResolver,
        remoteSchemas,
        baseURI: uri,
        rootSchema: remoteSchemas[uri],
      });
    }
    getValidateCache[uri] = {
      checkSchema: validator,
    };
    return validator;
  };

  const checkSchema = (valueSchema, value) => {
    if (valueSchema === true) {
      return undefined;
    }
    if (valueSchema === false) {
      return {
        message: 'No value is accepted',
      };
    }
    if (!isPlainObject(valueSchema)) {
      return {
        message: 'Unrecognized schema specification',
      };
    }
    if (typeof valueSchema.$ref === 'string') {
      const [
        relativeURI = '',
        jPointer = '',
      ] = valueSchema.$ref.split('#');

      if (!relativeURI || relativeURI === rootURI) {
        if (jPointer === '') {
          return checkSchema(rootSchema, value);
        }
        if (jPointer.charAt(0) === '/') {
          const key = jPointer
            .substr(1)
            .split('/')
            .map(chunk => decodeURIComponent(chunk).replace(/~1/g, '/').replace(/~0/g, '~'))
            .join('.');

          const subSchema = get(rootSchema, key);
          if (subSchema) {
            return checkSchema(subSchema, value);
          }
        }
      }

      if (jPointer && jPointer.charAt(0) !== '/') {
        const validate = getValidate(valueSchema.$ref);
        // NOTE: Double check if validate is a new function, to prevent infinite call stack.
        if (validate && validate !== checkSchema) {
          return validate(
            {
              $ref: '',
            },
            value,
          );
        }
      }

      if (relativeURI && relativeURI !== rootURI) {
        const validate = getValidate(relativeURI);
        // NOTE: Double check if validate is a new function, to prevent infinite call stack.
        if (validate && validate !== checkSchema) {
          return validate(
            {
              $ref: `#${jPointer}`,
            },
            value,
          );
        }
      }

      return {
        message: 'Bad reference',
      };
    }
    if (typeof value === 'undefined') {
      return undefined;
    }
    if (isArray(valueSchema.type)) {
      return checkSchema(
        {
          anyOf: map(valueSchema.type, type => ({
            type,
          })),
        },
        value,
      );
    }
    if (typeof valueSchema.type === 'string') {
      switch (valueSchema.type) {
        case 'null': {
          if (value !== null) {
            return {
              message: 'Value is not null',
            };
          }
          break;
        }
        case 'string': {
          if (typeof value !== 'string') {
            return {
              message: 'Value is not a string',
            };
          }
          if (valueSchema.format) {
            const format = formats[valueSchema.format];
            if (!format) {
              return {
                message: `Unknown format: ${valueSchema.format}`,
              };
            }
            if (!format.re.test(value)) {
              return {
                message: `Expected ${format.description}`,
              };
            }
          }
          break;
        }
        case 'integer':
        case 'number': {
          if (typeof value !== 'number' || isNaN(value)) {
            return {
              message: 'Value is not a number',
            };
          }
          if (valueSchema.type === 'integer' && value % 1 !== 0) {
            // NOTE: at this stage we already know that it's a number
            return {
              message: 'Value is not an integer',
            };
          }
          break;
        }
        case 'boolean': {
          if (typeof value !== 'boolean') {
            return {
              message: 'Value is not a boolean',
            };
          }
          break;
        }
        case 'object': {
          if (!isPlainObject(value)) {
            return {
              message: 'Value is not an object',
            };
          }
          break;
        }
        case 'array': {
          if (!isArray(value)) {
            return {
              message: 'Value is not an array',
            };
          }
          break;
        }
        case 'date': {
          if (!isDate(value) || isNaN(value.getTime())) {
            return {
              message: 'Value is not a date',
            };
          }
          break;
        }
        default: {
          // NOTE: We want support {} representing "any" type
          if (valueSchema.type) {
            return {
              message: `I don't know how to validate type: ${valueSchema.type}`,
            };
          }
        }
      }
    }
    if (isArray(valueSchema.enum)) {
      if (findIndex(valueSchema.enum, x => isEqual(x, value)) < 0) {
        return {
          message: `Value should be one of: ${valueSchema.enum.join(', ')}`,
        };
      }
    }
    if (has(valueSchema, 'const')) {
      if (!isEqual(valueSchema.const, value)) {
        return {
          message: `Value is not equal to ${valueSchema.const}`,
        };
      }
    }
    if (typeof value === 'number') {
      if (
        typeof valueSchema.minimum === 'number' &&
        value < valueSchema.minimum
      ) {
        return {
          message: `Expected value at least ${valueSchema.minimum}`,
        };
      }
      if (
        typeof valueSchema.exclusiveMinimum === 'number' &&
        value <= valueSchema.exclusiveMinimum
      ) {
        return {
          message: `Expected value greater than ${valueSchema.exclusiveMinimum}`,
        };
      }
      if (
        typeof valueSchema.maximum === 'number' &&
        value > valueSchema.maximum
      ) {
        return {
          message: `Expected value at most ${valueSchema.maximum}`,
        };
      }
      if (
        typeof valueSchema.exclusiveMaximum === 'number' &&
        value >= valueSchema.exclusiveMaximum
      ) {
        return {
          message: `Expected value less than ${valueSchema.exclusiveMaximum}`,
        };
      }
      if (
        typeof valueSchema.multipleOf === 'number' &&
        (value / valueSchema.multipleOf) % 1 !== 0
      ) {
        return {
          message: `Expected value to be multiple of ${valueSchema.multipleOf}`,
        };
      }
    }
    if (typeof value === 'string') {
      if (
        typeof valueSchema.minLength === 'number' &&
        strLength(value) < valueSchema.minLength
      ) {
        return {
          message: `Expected length at least ${valueSchema.minLength}`,
        };
      }
      if (
        typeof valueSchema.maxLength === 'number' &&
        strLength(value) > valueSchema.maxLength
      ) {
        return {
          message: `Expected length at most ${valueSchema.maxLength}`,
        };
      }
      if (typeof valueSchema.pattern === 'string') {
        const re = new RegExp(valueSchema.pattern);
        if (!re.test(value)) {
          return {
            message: `Value should match pattern: ${valueSchema.pattern}`,
          };
        }
      }
    }
    if (isPlainObject(value)) {
      const required = {};
      const errors = {};
      forEach(valueSchema.required, (key) => {
        required[key] = true;
      });
      const patterns = [];
      forEach(valueSchema.patternProperties, (schema, key) => {
        patterns.push({
          schema,
          regExp: new RegExp(key),
        });
      });
      const matched = {};
      forEach(value, (valueAtKey, key) => {
        if (valueSchema.properties && has(valueSchema.properties, key)) {
          const error = checkSchema(valueSchema.properties[key], valueAtKey);
          if (error) {
            errors[key] = error;
            return;
          }
          matched[key] = true;
        }
        for (let i = 0; i < patterns.length; i += 1) {
          const {
            schema,
            regExp,
          } = patterns[i];
          if (regExp.test(key)) {
            const error = checkSchema(schema, valueAtKey);
            if (error) {
              errors[key] = error;
              return;
            }
            matched[key] = true;
          }
        }
        if (!matched[key]) {
          if (has(valueSchema, 'additionalProperties')) {
            const error = checkSchema(
              valueSchema.additionalProperties,
              valueAtKey,
            );
            if (error) {
              errors[key] = error;
            }
          }
        }
      });
      if (!isEmpty(errors)) {
        return {
          errors,
        };
      }
      if (isArray(valueSchema.required)) {
        const n = valueSchema.required.length;
        for (let i = 0; i < n; i += 1) {
          const name = valueSchema.required[i];
          if (value[name] === undefined) {
            return {
              message: `Missing required property "${name}"`,
            };
          }
        }
      }
      if (has(valueSchema, 'propertyNames')) {
        const properties = keys(value);
        const n = properties.length;
        for (let i = 0; i < n; i += 1) {
          const error = checkSchema(valueSchema.propertyNames, properties[i]);
          if (error) {
            return {
              message: `${properties[i]}: ${error.message}`,
            };
          }
        }
      }
      if (isPlainObject(valueSchema.dependencies)) {
        const properties = keys(valueSchema.dependencies);
        const n = properties.length;
        for (let i = 0; i < n; i += 1) {
          const key = properties[i];
          if (value[key] !== undefined) {
            let error;
            if (isArray(valueSchema.dependencies[key])) {
              error = checkSchema(
                {
                  required: valueSchema.dependencies[key],
                },
                value,
              );
            } else {
              error = checkSchema(valueSchema.dependencies[key], value);
            }
            if (error) {
              return error;
            }
          }
        }
      }
      if (
        typeof valueSchema.minProperties === 'number' &&
        size(value) < valueSchema.minProperties
      ) {
        return {
          message: `Expected at least ${valueSchema.minProperties} properties`,
        };
      }
      if (
        typeof valueSchema.maxProperties === 'number' &&
        size(value) > valueSchema.maxProperties
      ) {
        return {
          message: `Expected at most ${valueSchema.maxProperties} properties`,
        };
      }
    }
    if (isArray(value)) {
      if (isArray(valueSchema.items)) {
        const errors = {};
        forEach(value, (valueAtKey, key) => {
          if (key < valueSchema.items.length) {
            const error = checkSchema(valueSchema.items[key], valueAtKey);
            if (error) {
              errors[key] = error;
            }
          } else if (has(valueSchema, 'additionalItems')) {
            const error = checkSchema(valueSchema.additionalItems, valueAtKey);
            if (error) {
              errors[key] = error;
            }
          }
        });
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
      } else if (has(valueSchema, 'items')) {
        const errors = {};
        forEach(value, (valueAtKey, key) => {
          const error = checkSchema(valueSchema.items, valueAtKey);
          if (error) {
            errors[key] = error;
          }
        });
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
      }
      if (has(valueSchema, 'contains')) {
        const n = value.length;
        let isMatch = false;
        for (let i = 0; i < n; i += 1) {
          const error = checkSchema(valueSchema.contains, value[i]);
          if (!error) {
            isMatch = true;
            break;
          }
        }
        if (!isMatch) {
          return {
            message: 'Value does not match any of the specified types',
          };
        }
      }
      if (
        typeof valueSchema.minItems === 'number' &&
        value.length < valueSchema.minItems
      ) {
        return {
          message: `Expected at least ${valueSchema.minItems} item(s)`,
        };
      }
      if (
        typeof valueSchema.maxItems === 'number' &&
        value.length > valueSchema.maxItems
      ) {
        return {
          message: `Expected at most ${valueSchema.maxItems} item(s)`,
        };
      }
    }
    if (has(valueSchema, 'minimum') && typeof value === 'number') {
      if (value < valueSchema.minimum) {
        return {
          message: `Expected value to be at least ${valueSchema.minimum}`,
        };
      }
    }
    if (isArray(valueSchema.anyOf)) {
      const n = valueSchema.anyOf.length;
      let isMatch = false;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.anyOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (!error) {
          isMatch = true;
          break;
        }
      }
      if (!isMatch) {
        return {
          message: 'Value does not match any of the specified types',
        };
      }
    }
    if (isArray(valueSchema.allOf)) {
      const n = valueSchema.allOf.length;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.allOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (error) {
          return error;
        }
      }
    }
    if (isArray(valueSchema.oneOf)) {
      const n = valueSchema.oneOf.length;
      let isMatch = false;
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.oneOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (!error) {
          if (isMatch) {
            return {
              message: 'Value matches more than one type',
            };
          }
          isMatch = true;
        }
      }
      if (!isMatch) {
        return {
          message: 'Value does not match any of the specified types',
        };
      }
    }
    if (has(valueSchema, 'if')) {
      const error = checkSchema(valueSchema.if, value);
      if (!error && has(valueSchema, 'then')) {
        return checkSchema(valueSchema.then, value);
      }
      if (error && has(valueSchema, 'else')) {
        return checkSchema(valueSchema.else, value);
      }
    }
    if (has(valueSchema, 'not')) {
      const error = checkSchema(valueSchema.not, value);
      if (!error) {
        return {
          message: 'Expected value not to match schema',
        };
      }
    }
    return undefined;
  };

  getValidateCache[rootURI] = {
    checkSchema,
  };

  return checkSchema;
}

const defaultCheckSchema = createCheckSchema({});
export default defaultCheckSchema;

export function getAllErrors(error) {
  const allErrors = [];
  if (!error) {
    return allErrors;
  }
  if (error.message) {
    allErrors.push({
      message: error.message,
    });
  }
  forEach(error.errors, (nestedError, key) => {
    const allNestedErrors = getAllErrors(nestedError);
    forEach(allNestedErrors, (nested) => {
      allErrors.push({
        key: nested.key ? `${key}.${nested.key}` : key,
        message: nested.message,
      });
    });
  });
  return allErrors;
}

export function getOneError(error) {
  if (!error) {
    return undefined;
  }
  if (error.message) {
    return {
      message: error.message,
    };
  }
  if (isPlainObject(error.errors)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in error.errors) {
      if (has(error.errors, key)) {
        const nested = getOneError(error.errors[key]);
        if (nested && nested.message) {
          return {
            key: nested.key ? `${key}.${nested.key}` : key,
            message: nested.message,
          };
        }
      }
    }
  }
  return undefined;
}

export function getErrorMessage(error) {
  if (!error) {
    return undefined;
  }
  const {
    key,
    message,
  } = getOneError(error);
  if (!message) {
    return undefined;
  }
  if (key) {
    return `${message} at "${key}"`;
  }
  return message;
}

export const isOfType = (valueSchema, value) => !defaultCheckSchema(valueSchema, value);
