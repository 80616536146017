import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import Cluster from '../primitives/Cluster';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  reset,
  types,
  sizes,
  ghost as ghostStyles,
  loading as loadingStyles,
  disabled as disabledStyles,
  iconOnly as iconOnlyStyles,
  block as blockStyles,
} from './styles';

const Component = styled.button`
  ${reset};

  box-sizing: content-box;
  border: 1px solid transparent;
  border-radius: ${theme('border.radius.2')};
  cursor: pointer;
  letter-spacing: 0.05em;
  padding: 0 ${theme('space.2')};
  max-width: ${theme('measure.1')};
  transition: all ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};

  &:focus-visible {
    box-shadow: 0 0 0 2px ${theme('color.focus')};
  }

  svg {
    height: 1em;
    width: 1em;
  }

  /* Modifiers */

  ${props => props.stylesType && types[props.stylesType]};
  ${props => props.size && sizes[props.size]};
  ${props => props.ghost && ghostStyles[props.stylesType]};
  ${props => props.disabled && disabledStyles};
  ${props => props.loading && loadingStyles};
  ${props => props.block && blockStyles};
  ${props => props.iconOnly && iconOnlyStyles};
`;

const CustomIcon = ({
  icon,
  loading,
}) => {
  if (loading) {
    return <LoadingOutlined />;
  }

  return icon;
};

const Button = ({
  'data-testid': datatestid,
  // eslint-disable-next-line react/prop-types
  className,
  htmlType,
  type,
  size,
  // eslint-disable-next-line react/prop-types
  icon,
  loading,
  disabled,
  block,
  ghost,
  onClick,
  children,
}) => {
  const handleOnClick = !loading && !disabled ? onClick : () => {};

  return (
    <Component
      data-testid={datatestid}
      className={className}
      type={htmlType}
      stylesType={type}
      size={size}
      onClick={handleOnClick}
      iconOnly={icon && !children}
      loading={loading || undefined}
      disabled={disabled}
      block={block}
      ghost={ghost}
    >
      {icon && !children && (
      <CustomIcon
        icon={icon}
        loading={loading}
      />
      )}
      {(icon || loading) && children ? (
        <Cluster
          space={2}
          justify="center"
        >
          <CustomIcon
            icon={icon}
            loading={loading}
          />
          <span>{children}</span>
        </Cluster>
      ) : (
        children
      )}
    </Component>
  );
};

Button.propTypes = {
  'data-testid': PropTypes.string,
  htmlType: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Button.defaultProps = {
  'data-testid': null,
  htmlType: 'button',
  type: 'default',
  size: 'default',
  icon: null,
  loading: false,
  disabled: false,
  block: false,
  ghost: false,
  onClick: () => {},
  children: null,
};

export default Button;
